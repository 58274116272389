@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@layer components {
  .hover {
    @apply cursor-pointer hover:text-[#fe5e00];
  }
}
html {
  scroll-behavior: smooth;
}
